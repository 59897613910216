import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    document.addEventListener("turbo:before-fetch-request", (event) => {
      const ignoreLoading = event.target.dataset.ignoreLoading;
      if (ignoreLoading !== "true") {
        event.target.classList.add("tw-opacity-25");
      }
    });

    document.addEventListener("turbo:frame-render", async (event) => {
      event.target.classList.remove("tw-opacity-25")
    })
  }
}
